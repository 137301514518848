import * as React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"

// The bridge for Gatsby Background Image in V3
import { BgImage } from 'gbimage-bridge'

// All the gatsby-plugin-image goodness
import { getImage } from "gatsby-plugin-image"

import { Container, Row, Col } from "react-bootstrap"

const SecondPage = ({data}) => (
  <Layout>
    <BgImage 
      image={getImage(data.indexImage)} 
      className="masthead"
    >
       <div className="black-overlay">
            <div className="content-box">
              <h1>Schedule a Tour</h1>
            </div>
          </div>
    </BgImage>

    <Container>
                <Row>
                    <Col lg={6}>
                    &nbsp;
                    </Col>
                    <Col lg={6}>
                      <p>Success!</p>
                    </Col>
                </Row>
            </Container>




  </Layout>
)

export const Head = () => <Seo title="Success" />

export default SecondPage

export const query = graphql`
  query {
    indexImage: file(relativePath: {eq: "wick-1-outside-cars.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2000
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`